.bg {
  height: 100%;

  background-color: var(--theme-background-color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100);
  }

  :global(html.theme-light) & {
    // background-image: url('../assets/chat-bg-br.png');
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background-image: url('../assets/chat-bg-pattern-light.png');
    background-position: top right;
    background-size: 510px auto;
    background-repeat: repeat;
    mix-blend-mode: overlay;

    :global(html.theme-dark) & {
      // background-image: url('../assets/chat-bg-pattern-dark.png');
      mix-blend-mode: unset;
    }

    @media (max-width: 600px) {
      bottom: auto;
      height: calc(var(--vh, 1vh) * 100);
    }
  }
}
