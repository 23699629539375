.tg-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  z-index: 1000;
}

.tg-spinner {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3390ec; /* Telegram blue */
  animation: tg-spin 1s linear infinite;
}

@keyframes tg-spin {
  to {
    transform: rotate(360deg);
  }
}

/* Dark theme support */
.theme-dark .tg-fullscreen-loader {
  background-color: rgba(33, 33, 33, 0.8);
}

.theme-dark .tg-spinner {
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: #3390ec;
}
